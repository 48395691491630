import * as React from 'react';
import LandingPage from '../../components/landing/LandingPage';
import { VARIANT_TYPES } from '../../components/landing/type';
import { CUSTOM_EVENT_PYT, pushEvent } from '../../configs/clevertap';

export default function YoungEntrepreneur() {
  const onLoadCustomEvent = async () => {
    await pushEvent(CUSTOM_EVENT_PYT.YOUNG_ENTREPRENEUR.LOAD);
  };

  React.useEffect(() => {
    onLoadCustomEvent();
  }, []);

  return <LandingPage variant={VARIANT_TYPES.YOUNG_ENTREPRENEUR} />;
}
